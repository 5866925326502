<template>
  <v-app>
    <v-app-bar app clipped-left>
      <v-toolbar-title class="font-weight-black" style="cursor: pointer;"
        >JC3ポータル 管理</v-toolbar-title
      >
      <v-divider class="mx-4" vertical></v-divider>
      <v-spacer></v-spacer>

      <v-menu v-model="profileMenu" offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon class="mr-1" v-bind="attrs" v-on="on">
            <v-avatar v-if="$auth.user.picture">
              <img :src="$auth.user.picture" />
            </v-avatar>
            <v-icon v-else size="45px">mdi-account-circle</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <img :src="$auth.user.picture" />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ $auth.user.email }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>
          <v-list>
            <v-list-item link @click="goTop">
              <v-list-item-title>ポータルトップ</v-list-item-title>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <div class="text-center">
            <v-btn class="my-3" outlined @click="logout">ログアウト</v-btn>
          </div>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-main>
      <v-container>
        <v-navigation-drawer
          v-model="drawer"
          clipped
          :mini-variant.sync="mini"
          permanent
          app
        >
          <v-list-item color="grey lighten-4">
            <v-list-item-icon>
              <v-btn icon x-small @click.stop="mini = !mini">
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </v-list-item-icon>

            <v-list-item-title>管理メニュー</v-list-item-title>

            <v-btn icon @click.stop="mini = !mini">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-list-item>

          <v-divider></v-divider>

          <v-list>
            <v-list-item
              v-for="link in links"
              :key="link.text"
              link
              @click="toPage(link.link)"
            >
              <v-list-item-icon>
                <v-icon>{{ link.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ link.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    profileMenu: "",
    drawer: true,
    links: [
      {
        icon: "mdi-school",
        text: "教育コンテンツ",
        link: "Contents",
      },
      {
        icon: "mdi-bullhorn",
        text: "お知らせ",
        link: "Announcements",
      },
      {
        icon: "mdi-chat",
        text: "問い合わせ",
        link: "Contacts",
      },
      {
        icon: "mdi-chat",
        text: "外部教育口コミ",
        link: "ExReview",
      },
    ],
    mini: false,
  }),
  methods: {
    goTop() {
      window.location.href = "/";
    },
    doSearch() {
      this.search = this.search.trim();
      if (!this.search) return;
      this.$router.push({ name: "Search", query: { q: this.search } });
    },
    logout() {
      this.$auth.logout();
    },
    toPage(link) {
      this.$router.push({ name: link });
    },
  },
};
</script>

<style>
@import "~vuetify/dist/vuetify.min.css";
@import "https://cdn.jsdelivr.net/npm/@mdi/font@6.x/css/materialdesignicons.min.css";
</style>
